import * as React from "react";

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import PageTitle from '../components/PageTitle';
import TitleSection from "../components/TitleSection";
import ServiceList from "../components/ServiceList";
import BookingForm from "../components/BookingForm";

const Services = () => (
  <Layout>
    <Seo title="Services" />
    <PageTitle title={'Services'}/>

    <TitleSection title={"Our Services"}>
      <div className={`flex flex-col md:flex-row mx:space-x-12`}>

        <div className={`md:w-1/3`} data-aos={"fade"}>
          <p className={`text-xl font-medium mb-4 md:mb-0`}>
            Adrian Rowe Haircutters is one of Norwich's longest established hair salons.
          </p>
        </div>

        <div className={`md:w-2/3 space-y-4`} data-aos={"fade"} data-aos-delay={"200"}>
          <p className={`text-lg font-light`}>
            We specialise in the high-quality cutting, colouring, balayage and blow-drying of all lengths and styles of hair for both ladies and gentlemen.
            We welcome all ages, all hair type.
          </p>
          <p className={`text-lg font-light`}>
            Hair is our passion, and we are dedicated to helping the people of Norwich, look and feel their best.
          </p>
        </div>

      </div>
    </TitleSection>

    <ServiceList/>
    <BookingForm/>

  </Layout>
)

export default Services;
