import React, { useState } from "react";
import { navigate } from "gatsby";


function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}


const BookingForm = () => {

  const [state, setState] = useState({});

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => console.log(error));
  }


  return (
    <section className={`bg-gray-50 py-12 md:py-20`}>
      <div className={`relative max-w-6xl mx-auto px-4 md:px-6`}>

        <h2 className={`text-black text-3xl font-bold uppercase mb-4`}>Make a Booking<span className={`text-red`}>.</span></h2>

        <div className={`flex flex-col md:flex-row md:space-x-8`}>
          <div className={`w-full md:w-1/3`}>
            <p>Please fill out our short form and somebody will be in touch with you to confirm your booking.</p>
          </div>

          <div className={`w-full md:w-2/3`}>

            <form name="booking-form"
                  method="POST"
                  netlify-honeypot="bot-field"
                  data-netlify="true"
                  action={"/thanks"}
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="booking-form" />


              {/* Your Details */}
              <fieldset className={`flex flex-col space-y-4 mb-8`}>
                <legend className={`text-black text-lg font-bold uppercase`}>Your Details<span
                  className={`text-red`}>.</span></legend>

                <input
                  className={`lg:w-1/2 py-4 border-transparent placeholder:text-gray placeholder:uppercase focus:ring-red focus:border-red transition duration-300 ease-in-out`}
                  type={'text'}
                  placeholder={'Your Name'}
                  name={"name"}
                  required
                />

                <input
                  className={`lg:w-1/2 py-4 border-transparent placeholder:text-gray placeholder:uppercase focus:ring-red focus:border-red transition duration-300 ease-in-out`}
                  type={'email'}
                  placeholder={'Email'}
                  name={"email"}
                  required
                />

                <input
                  className={`lg:w-1/2 py-4 border-transparent placeholder:text-gray placeholder:uppercase focus:ring-red focus:border-red transition duration-300 ease-in-out`}
                  type={'text'}
                  placeholder={'Telephone'}
                  name={"phone"}
                  required
                />

              </fieldset>


              <fieldset className={`flex flex-col space-y-4 mb-8`}>
                <legend className={`text-black text-lg font-bold uppercase`}>Services You're Interested In<span
                  className={`text-red`}>.</span></legend>

                <div className={'grid grid-cols-2'}>

                  <div className={"relative flex items-center mb-4"}>
                    <input
                      id={"wash_cut_blowdry"}
                      name={"services"}
                      type={"checkbox"}
                      className={"border-transparent focus:border-transparent focus:ring-red focus:drop-shadow-none checked:text-red h-8 w-8 cursor-pointer transition duration-300 ease-in-out"}
                      value={"Wash, Cut & Blowdry"}
                    />
                    <label htmlFor={"wash_cut_blowdry"} className={'ml-3 cursor-pointer'}>Wash, Cut & Blowdry</label>
                  </div>

                  <div className={"relative flex items-center mb-4"}>
                    <input
                      id={"styling_blowdry"}
                      name={"services"}
                      type={"checkbox"}
                      className={"border-transparent focus:border-transparent focus:ring-red focus:drop-shadow-none checked:text-red h-8 w-8 cursor-pointer transition duration-300 ease-in-out"}
                      value={"Styling / Blowdry"}
                    />
                    <label htmlFor={"styling_blowdry"} className={"ml-3 cursor-pointer"}>Styling / Blowdry</label>
                  </div>

                  <div className={"relative flex items-center mb-4"}>
                    <input
                      id={"colour_treatments"}
                      name={"services"}
                      type={"checkbox"}
                      className={"border-transparent focus:border-transparent focus:ring-red focus:drop-shadow-none checked:text-red h-8 w-8 cursor-pointer transition duration-300 ease-in-out"}
                      value={"Colour Treatments"}
                    />
                    <label htmlFor={"colour_treatments"} className={"ml-3 cursor-pointer"}>Colour Treatments</label>
                  </div>

                  <div className={"relative flex items-center mb-4"}>
                    <input
                      id={"balayage"}
                      name={"services"}
                      type={"checkbox"}
                      className={"border-transparent focus:border-transparent focus:ring-red focus:drop-shadow-none checked:text-red h-8 w-8 cursor-pointer transition duration-300 ease-in-out"}
                      value={"Balayage"}
                    />
                    <label htmlFor={"balayage"} className={"ml-3 cursor-pointer"}>Balayage</label>
                  </div>

                  <div className={"relative flex items-center mb-4"}>
                    <input
                      id={"gents_cut"}
                      name={"services"}
                      type={"checkbox"}
                      className={"border-transparent focus:border-transparent focus:ring-red focus:drop-shadow-none checked:text-red h-8 w-8 cursor-pointer transition duration-300 ease-in-out"}
                      value={"Gents Cut"}
                    />
                    <label htmlFor={"gents_cut"} className={"ml-3 cursor-pointer"}>Gents Cut</label>
                  </div>

                  <div className={"relative flex items-center mb-4"}>
                    <input
                      id={"childrens_cut"}
                      name={"services"}
                      type={"checkbox"}
                      className={"border-transparent focus:border-transparent focus:ring-red focus:drop-shadow-none checked:text-red h-8 w-8 cursor-pointer transition duration-300 ease-in-out"}
                      value={"Children's Cut"}
                    />
                    <label htmlFor={"childrens_cut"} className={"ml-3 cursor-pointer"}>Children's Cut</label>
                  </div>

                  <div className={"relative flex items-center mb-4"}>
                    <input
                      id={"vip_at_home"}
                      name={"services"}
                      type={"checkbox"}
                      className={"border-transparent focus:border-transparent focus:ring-red focus:drop-shadow-none checked:text-red h-8 w-8 cursor-pointer transition duration-300 ease-in-out"}
                      value={"VIP At Home"}
                    />
                    <label htmlFor={"vip_at_home"} className={"ml-3 cursor-pointer"}>Our "VIP At Home" Service</label>
                  </div>

                </div>

              </fieldset>


              <fieldset className={`flex flex-col space-y-4 mb-8`}>
                <legend className={`text-black text-lg font-bold uppercase`}>When Would You Like To Come In?</legend>

                <div className={'grid grid-cols-2'}>
                  <div className={'pr-12'}>
                    <input
                      className={`w-full py-4 border-transparent placeholder:text-gray placeholder:uppercase focus:ring-red focus:border-red transition duration-300 ease-in-out`}
                      type={'text'}
                      placeholder={'DD/MM/YYYY'}
                      name={"date"}
                    />
                  </div>


                  <div className={'flex flex-col'}>
                    <div className={"relative flex items-center mb-4"}>
                      <input
                        id={"morning"}
                        name={"time"}
                        type={"checkbox"}
                        className={"border-transparent focus:border-transparent focus:ring-red focus:drop-shadow-none checked:text-red h-8 w-8 cursor-pointer transition duration-300 ease-in-out"}
                        value={"Morning"}
                      />
                      <label htmlFor={"morning"} className={"ml-3 cursor-pointer"}>Morning</label>
                    </div>

                    <div className={"relative flex items-center mb-4"}>
                      <input
                        id={"afternoon"}
                        name={"time"}
                        type={"checkbox"}
                        className={"border-transparent focus:border-transparent focus:ring-red focus:drop-shadow-none checked:text-red h-8 w-8 cursor-pointer transition duration-300 ease-in-out"}
                        value={"Afternoon"}
                      />
                      <label htmlFor={"afternoon"} className={"ml-3 cursor-pointer"}>Afternoon</label>
                    </div>

                    <div className={"relative flex items-center mb-4"}>
                      <input
                        id={"evening"}
                        name={"time"}
                        type={"checkbox"}
                        className={"border-transparent focus:border-transparent focus:ring-red focus:drop-shadow-none checked:text-red h-8 w-8 cursor-pointer transition duration-300 ease-in-out"}
                        value={"Evening"}
                      />
                      <label htmlFor={"evening"} className={"ml-3 cursor-pointer"}>Evening</label>
                    </div>
                  </div>

                </div>

              </fieldset>


              <fieldset className={`flex flex-col space-y-4 mb-8`}>
                <legend className={`text-black text-lg font-bold uppercase`}>Anything Else?</legend>

                <textarea
                  className={`w-full py-4 border-transparent placeholder:text-gray placeholder:uppercase focus:ring-red focus:border-red transition duration-300 ease-in-out`}
                  type={'text'}
                  placeholder={''}
                  rows={6}
                  name={"message"}
                />
              </fieldset>

              <div className={'flex justify-end'}>
                <button
                  className={`w-auto flex flex-row group text-black px-6 py-2 border-black border border-solid hover:bg-red hover:border-red transition ease-in-out duration-300`}>
                  <span
                    className={`uppercase group-hover:text-white transition ease-in-out duration-300 mr-1`}>Submit</span>

                  <svg className={`w-6 h-6 text-red group-hover:text-white transition ease-in-out duration-300`}
                       fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </button>
              </div>


            </form>


          </div>
        </div>

      </div>
    </section>
  )

}

export default BookingForm;