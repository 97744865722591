import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";


const PageTitle = ({ title }) => {

  const bg = "bg";

  return (
    <section className={'relative border-b-8 border-red border-solid'}>
      <div className="absolute inset-0 bg-black pointer-events-none -z-10" aria-hidden="true">
        <div className="w-full h-full" data-aos={"fade"}>
          <StaticImage
            className={"opacity-20 w-full h-full object-cover"}
            src={`../images/${ bg }.jpg`} alt={'Hero'}
          />
        </div>
      </div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-36 pb-24 md:pt-48 md:pb-36">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="font-bold uppercase text-4xl lg:text-5xl font-sans text-white" data-aos={"fade-up"}>{ title }<span className={'text-red'}>.</span></h1>
          </div>

        </div>
      </div>
    </section>
  );

}


export default PageTitle;
