import * as React from "react";

const TitleSection = ({ title, children }) => {

  return (
    <section className={'relative'}>
      <div className={'relative max-w-6xl mx-auto px-6 sm:px-8'}>
        <div className={'py-12 md:pt-20 space-y-8'}>

          <h2 className={`text-4xl text-black font-bold uppercase`} data-aos={"fade"}>{ title }<span className={`text-red`}>.</span></h2>

          { children }

        </div>
      </div>
    </section>
  )

}

export default TitleSection;