import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";


const ServiceList = () => {

  return (
    <section>
      <div className={`relative max-w-6xl mx-auto px-6 sm:px-8`}>
        <div className={`pb-20 grid gap-8 space-y-4`}>

          <article className={`bg-gray-50 p-4 flex flex-col md:flex-row items-center`} data-aos={"fade"}>
            <div className={`md:w-1/4 md:pr-8 mb-4 md:mb-0 inset-0`}>
              <StaticImage src={'../images/service-haircut.jpg'} alt={'Haircuts'}/>
            </div>

            <div className={'md:w-3/4'}>
              <h2 className={`font-bold text-black text-2xl uppercase mb-4`}>Haircuts<span className={`text-red`}>.</span></h2>
              <p className={`font-light mb-4`}>
                We aspire to create beautiful haircuts using the latest techniques, that complement your face shape, style, and personality,
                and that work for your everyday lifestyle. Whether you prefer fashionable or classical and easy to style, we will ensure that
                leave the salon feeling confident, chic, and amazing!
              </p>
              <p className={'font-light mb-4'}>
                Since we prefer to treat each client with the attention that they deserve, we always allow enough time for a thorough consultation
                prior to any haircut.
              </p>
              <p className={'font-light'}>
                Before leaving us, we provide expert advice on how to look after your hair yourself at home, as well as advice on Matrix after-care products to keep you hair looking healthy and vibrant until your next visit.
              </p>
            </div>
          </article>

          <article className={`bg-gray-50 p-4 flex flex-col md:flex-row items-center`} data-aos={"fade"}>
            <div className={`md:w-1/4 md:pr-8 inset-0 mb-4 md:mb-0`}>
              <StaticImage className={'object-cover'} src={'../images/service-haircolour.jpg'} alt={'Hair Colouring'}/>
            </div>

            <div className={'md:w-3/4'}>
              <h2 className={`font-bold text-black text-2xl uppercase mb-4`}>Hair Colour<span className={`text-red`}>.</span></h2>
              <p className={`font-light mb-4`}>
                We firmly believe that an exceptional haircut is not complete unless your hair colour is perfect, and the
                perfect hair colour for you is a shade that complements your skin tone and eye colour. The ideal colour
                should also be easily maintainable and suit your lifestyle choices.
              </p>
              <p className={`font-light mb-4`}>
                Whether you require a little maintenance or a full colour transformation, our stylists have the technical knowledge
                and experience to deliver truly stunning results.
              </p>
              <p className={`font-light mb-4`}>
                We only use high-quality Matrix products which help to maintain the integrity of your hair, and provide you with
                long-lasting, radiant colour.
              </p>
              <p className={`font-light`}>
                <span className={'font-bold'}>Please note:</span> All new clients must have a skin test at least 48 hours prior to their first colour appointment.
                All colour and perms require a £30 deposit which is non-refundable. Re-scheduling is permitted with salon consent.
              </p>
            </div>
          </article>


          <article className={`bg-gray-50 p-4 flex flex-col md:flex-row items-center`} data-aos={"fade"}>
            <div className={`md:w-1/4 md:pr-8 inset-0 mb-4 md:mb-0`}>
              <StaticImage className={'object-center'} src={'../images/service-balayage.jpg'} alt={'Balayage'}/>
            </div>

            <div className={'md:w-3/4'}>
              <h2 className={`font-bold text-black text-2xl uppercase mb-4`}>Balayage<span className={`text-red`}>.</span></h2>
              <p className={`font-light mb-4`}>
                For conceivably the best balayage in Norwich, look no further!
              </p>
              <p className={`font-light mb-4`}>
                The word originates from the French “to sweep”, where lightener is free-hand painted to the hair. This creates a blended,
                sun-kissed natural look that adds movement and dimension to the hair.
              </p>
              <p className={`font-light mb-4`}>
                Balayage has gentler, less visible re-growth lines than traditional highlights reflecting the parts of your hair
                that would naturally shimmer in the sun. Since Balayage is hand-painted, the stylist can choose the ideal placement
                in order to complement your skin, hair and features to achieve the perfect look!
              </p>
            </div>
          </article>


          <article className={`bg-gray-50 p-4 flex flex-col md:flex-row items-center`} data-aos={"fade"}>
            <div className={`md:w-1/4 md:pr-8 inset-0 mb-4 md:mb-0`}>
              <StaticImage className={'h-full object-cover'} src={'../images/service-blowdry.jpg'} alt={'Blowdry'}/>
            </div>

            <div className={'md:w-3/4'}>
              <h2 className={`font-bold text-black text-2xl uppercase mb-4`}>Blowdry<span className={`text-red`}>.</span></h2>
              <p className={`font-light`}>
                Our professional blow-dry service is tailored to suit each client’s needs. Whether you’re on the way to an important event,
                party, night out, or just want to feel fabulous, our stylists are ready and waiting!
              </p>
            </div>
          </article>


          <article className={`bg-gray-50 p-4 flex flex-col md:flex-row items-center`} data-aos={"fade"}>
            <div className={`md:w-1/4 md:pr-8 inset-0 mb-4 md:mb-0`}>
              <StaticImage className={'object-center'} src={'../images/service-braiding.jpg'} alt={'Braiding'}/>
            </div>

            <div className={'md:w-3/4'}>
              <h2 className={`font-bold text-black text-2xl uppercase mb-4`}>Braiding<span className={`text-red`}>.</span></h2>
              <p className={`font-light`}>
                Looking for an extra-special look for a special wedding or event? We can help! Our stylists are experienced when it comes to
                braiding, so can help you to prepare and leave your hair looking flawless.
              </p>
            </div>
          </article>

        </div>
      </div>
    </section>
  )

}

export default ServiceList;